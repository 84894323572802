import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IIconProperty } from 'types/iconProps';

const FormulaIcon = ({ width, height, color }: IIconProperty) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 20 20"
      style={{ transform: 'scale(1)', width, height }}
    >
      <path
        d="M17.7083 0H2.29167C1.0275 0 0 1.0275 0 2.29167V17.7083C0 18.9725 1.0275 20 2.29167 20H17.7083C18.9725 20 20 18.9725 20 17.7083V2.29167C20 1.0275 18.9725 0 17.7083 0ZM8.54167 9.375C8.88667 9.375 9.16667 9.655 9.16667 10C9.16667 10.345 8.88667 10.625 8.54167 10.625H6.25V12.7083C6.25 13.0533 5.97 13.3333 5.625 13.3333C5.28 13.3333 5 13.0533 5 12.7083V8.95833C5 7.69417 6.0275 6.66667 7.29167 6.66667H8.54167C8.88667 6.66667 9.16667 6.94667 9.16667 7.29167C9.16667 7.63667 8.88667 7.91667 8.54167 7.91667H7.29167C6.7175 7.91667 6.25 8.38417 6.25 8.95833V9.375H8.54167ZM13.3333 12.7083C13.3333 13.0533 13.0533 13.3333 12.7083 13.3333C12.3633 13.3333 12.0833 13.0533 12.0833 12.7083V7.91667H11.4583C11.1133 7.91667 10.8333 7.63667 10.8333 7.29167C10.8333 6.94667 11.1133 6.66667 11.4583 6.66667H12.7083C13.0533 6.66667 13.3333 6.94667 13.3333 7.29167V12.7083Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default FormulaIcon;
