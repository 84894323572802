import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IIconProperty } from 'types/iconProps';

const ClockIcon = ({ width, height, color }: IIconProperty) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 20 20"
      style={{ transform: 'scale(1)', width, height }}
    >
      <path
        d="M10 18.9583C5.05835 18.9583 1.04169 14.9417 1.04169 10C1.04169 5.05834 5.05835 1.04167 10 1.04167C14.9417 1.04167 18.9584 5.05834 18.9584 10C18.9584 14.9417 14.9417 18.9583 10 18.9583ZM10 2.29167C5.75002 2.29167 2.29169 5.75001 2.29169 10C2.29169 14.25 5.75002 17.7083 10 17.7083C14.25 17.7083 17.7084 14.25 17.7084 10C17.7084 5.75001 14.25 2.29167 10 2.29167Z"
        fill="white"
      />
      <path
        d="M13.0917 13.275C12.9833 13.275 12.875 13.25 12.775 13.1833L10.1917 11.6417C9.55001 11.2583 9.07501 10.4167 9.07501 9.675V6.25833C9.07501 5.91666 9.35835 5.63333 9.70001 5.63333C10.0417 5.63333 10.325 5.91666 10.325 6.25833V9.675C10.325 9.975 10.575 10.4167 10.8333 10.5667L13.4167 12.1083C13.7167 12.2833 13.8083 12.6667 13.6333 12.9667C13.5083 13.1667 13.3 13.275 13.0917 13.275Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default ClockIcon;
