import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IIconProperty } from 'types/iconProps';

const DeleteIcon = ({ width, height, color }: IIconProperty) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 20 20"
      style={{ transform: 'scale(1)', width, height }}
    >
      <path
        d="M12.4916 0.666504H5.50829C2.47496 0.666504 0.666626 2.47484 0.666626 5.50817V12.4832C0.666626 15.5248 2.47496 17.3332 5.50829 17.3332H12.4833C15.5166 17.3332 17.325 15.5248 17.325 12.4915V5.50817C17.3333 2.47484 15.525 0.666504 12.4916 0.666504ZM11.8 10.9165C12.0416 11.1582 12.0416 11.5582 11.8 11.7998C11.675 11.9248 11.5166 11.9832 11.3583 11.9832C11.2 11.9832 11.0416 11.9248 10.9166 11.7998L8.99996 9.88317L7.08329 11.7998C6.95829 11.9248 6.79996 11.9832 6.64163 11.9832C6.48329 11.9832 6.32496 11.9248 6.19996 11.7998C5.95829 11.5582 5.95829 11.1582 6.19996 10.9165L8.11663 8.99984L6.19996 7.08317C5.95829 6.8415 5.95829 6.4415 6.19996 6.19984C6.44163 5.95817 6.84163 5.95817 7.08329 6.19984L8.99996 8.1165L10.9166 6.19984C11.1583 5.95817 11.5583 5.95817 11.8 6.19984C12.0416 6.4415 12.0416 6.8415 11.8 7.08317L9.88329 8.99984L11.8 10.9165Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default DeleteIcon;
