import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IIconProperty } from 'types/iconProps';

const CloudIcon = ({ width, height, color }: IIconProperty) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 16 16"
      style={{ transform: 'scale(1)', width, height }}
      fill="none"
    >
      <path
        d="M13.0125 3.86264C12.8415 2.84598 12.3381 1.91816 11.5682 1.21407C10.7127 0.43101 9.60066 0 8.44253 0C7.54761 0 6.67571 0.256632 5.92885 0.740284C5.30701 1.14168 4.79046 1.68456 4.42525 2.32285C4.26732 2.29323 4.10282 2.27678 3.93831 2.27678C2.54 2.27678 1.4016 3.41518 1.4016 4.81349C1.4016 4.99445 1.42134 5.16883 1.45425 5.33991C0.549455 5.99794 0 7.05737 0 8.18589C0 9.09727 0.338886 9.98232 0.957434 10.6831C1.59243 11.4004 2.43142 11.8248 3.32634 11.8742C3.33621 11.8742 3.34279 11.8742 3.35266 11.8742H6.18219C6.42895 11.8742 6.62636 11.6767 6.62636 11.43C6.62636 11.1832 6.42895 10.9858 6.18219 10.9858H3.36582C2.02015 10.9036 0.888341 9.62369 0.888341 8.1826C0.888341 7.25149 1.38844 6.38289 2.19453 5.9124C2.38207 5.80383 2.46103 5.57681 2.38865 5.37282C2.32285 5.19515 2.28994 5.00761 2.28994 4.80691C2.28994 3.89883 3.03023 3.15854 3.93831 3.15854C4.13243 3.15854 4.32326 3.19145 4.50093 3.25725C4.71808 3.33621 4.95826 3.23751 5.05696 3.03023C5.67222 1.72404 7.00144 0.88176 8.44582 0.88176C10.387 0.88176 11.9893 2.33601 12.1736 4.26404C12.1933 4.46473 12.3446 4.62595 12.5421 4.65885C14.0062 4.9089 15.1117 6.26116 15.1117 7.80424C15.1117 9.43944 13.8252 10.8608 12.2394 10.9825H9.81452C9.56776 10.9825 9.37035 11.1799 9.37035 11.4267C9.37035 11.6735 9.56776 11.8709 9.81452 11.8709H12.2558C12.2657 11.8709 12.2756 11.8709 12.2887 11.8709C13.2922 11.7985 14.2299 11.3379 14.9274 10.568C15.6216 9.80465 16 8.82418 16 7.80424C15.9967 5.95846 14.7366 4.31668 13.0125 3.86264Z"
        fill="url(#paint0_linear_4442_42072)"
      />
      <path
        d="M10.6669 8.60467C10.8413 8.43029 10.8413 8.15063 10.6669 7.97625L8.31442 5.62379C8.23217 5.54154 8.11701 5.49219 8.00186 5.49219C7.8867 5.49219 7.77155 5.53825 7.6893 5.62379L5.33684 7.97625C5.16246 8.15063 5.16246 8.43029 5.33684 8.60467C5.42238 8.69021 5.53754 8.73628 5.6494 8.73628C5.76127 8.73628 5.87642 8.6935 5.96197 8.60467L7.55769 7.00895V14.3427C7.55769 14.5895 7.7551 14.7869 8.00186 14.7869C8.24862 14.7869 8.44603 14.5895 8.44603 14.3427V7.00895L10.0418 8.60467C10.2128 8.77905 10.4925 8.77905 10.6669 8.60467Z"
        fill="url(#paint1_linear_4442_42072)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4442_42072"
          x1="-1.19209e-07"
          y1="5.93708"
          x2="16"
          y2="5.93708"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3FADD5" />
          <stop offset="1" stopColor="#3FADD5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4442_42072"
          x1="5.20605"
          y1="10.1395"
          x2="10.7977"
          y2="10.1395"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3FADD5" />
          <stop offset="1" stopColor="#3FADD5" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default CloudIcon;
