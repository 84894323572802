import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

import { IIconProperty } from 'types/iconProps';

const TeamIcon = ({ width, height, color }: IIconProperty) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 72 62"
      style={{ transform: 'scale(1)', width, height }}
    >
      <g opacity="0.2" clipPath="url(#clip0_9616_51779)">
        <path
          d="M0.28125 51.75V56.5313H4.78125V49.7813C4.78344 48.4195 5.30861 47.1106 6.24825 46.125H5.91075C5.17169 46.1244 4.43975 46.2695 3.75677 46.5519C3.0738 46.8343 2.45317 47.2485 1.93037 47.7709C1.40756 48.2933 0.992822 48.9136 0.709858 49.5964C0.426894 50.2791 0.28125 51.0109 0.28125 51.75Z"
          fill="white"
        />
        <path
          d="M0.28125 64.125C0.28125 64.3488 0.370145 64.5634 0.528379 64.7216C0.686612 64.8799 0.901224 64.9688 1.125 64.9688H4.78125V58.2188H0.28125V64.125Z"
          fill="white"
        />
        <path
          d="M14.6261 45.5609C13.862 45.5602 13.1028 45.4394 12.3761 45.2031V46.1234H11.5886C11.5517 46.3086 11.5325 46.497 11.5312 46.6859C11.5313 47.5064 11.8572 48.2933 12.4374 48.8735C13.0176 49.4537 13.8045 49.7796 14.625 49.7796C15.4455 49.7796 16.2324 49.4537 16.8126 48.8735C17.3928 48.2933 17.7188 47.5064 17.7188 46.6859C17.7194 46.497 17.7009 46.3085 17.6636 46.1234H16.8761V45.2031C16.1495 45.4394 15.3902 45.5602 14.6261 45.5609Z"
          fill="white"
        />
        <path
          d="M30.5917 12.3438L29.6051 18.1004C29.5784 18.2556 29.5957 18.4151 29.6549 18.561C29.7142 18.7068 29.8131 18.8332 29.9405 18.9258C30.0678 19.0184 30.2185 19.0735 30.3756 19.0849C30.5326 19.0963 30.6897 19.0635 30.8291 18.9903L35.9984 16.2723L41.1734 18.9903C41.3128 19.0635 41.4699 19.0963 41.6269 19.0849C41.784 19.0735 41.9347 19.0184 42.062 18.9258C42.1894 18.8332 42.2883 18.7068 42.3476 18.561C42.4068 18.4151 42.4241 18.2556 42.3974 18.1004L41.4108 12.3438L45.5868 8.2679C45.6994 8.15791 45.7789 8.01867 45.8166 7.86588C45.8543 7.71308 45.8485 7.55281 45.8 7.40311C45.7514 7.25341 45.6621 7.12025 45.5419 7.01862C45.4218 6.91699 45.2756 6.85094 45.1199 6.8279L39.3397 5.98753L36.7556 0.750655C36.686 0.609206 36.5782 0.490075 36.4444 0.406765C36.3105 0.323455 36.1561 0.279297 35.9984 0.279297C35.8408 0.279297 35.6863 0.323455 35.5525 0.406765C35.4187 0.490075 35.3109 0.609206 35.2413 0.750655L32.6538 5.98753L26.8736 6.8279C26.7179 6.85094 26.5717 6.91699 26.4516 7.01862C26.3314 7.12025 26.2421 7.25341 26.1935 7.40311C26.145 7.55281 26.1392 7.71308 26.1769 7.86588C26.2146 8.01867 26.2941 8.15791 26.4067 8.2679L30.5917 12.3438Z"
          fill="white"
        />
        <path
          d="M51.9674 11.7517L51.2462 15.9603C51.2196 16.1155 51.2368 16.2751 51.2961 16.4209C51.3554 16.5668 51.4543 16.6932 51.5816 16.7857C51.709 16.8783 51.8597 16.9334 52.0167 16.9448C52.1738 16.9562 52.3309 16.9235 52.4702 16.8502L56.2491 14.8635L60.028 16.8502C60.1674 16.9235 60.3245 16.9562 60.4815 16.9448C60.6385 16.9334 60.7892 16.8783 60.9166 16.7857C61.0439 16.6932 61.1428 16.5668 61.2021 16.4209C61.2614 16.2751 61.2787 16.1155 61.252 15.9603L60.5309 11.7517L63.5875 8.77385C63.7001 8.66392 63.7798 8.52466 63.8175 8.37183C63.8551 8.21899 63.8493 8.05867 63.8006 7.90899C63.7519 7.7593 63.6623 7.62622 63.542 7.52478C63.4216 7.42334 63.2753 7.35759 63.1195 7.33497L58.8962 6.72072L57.0062 2.89572C56.9364 2.75462 56.8284 2.63585 56.6947 2.55282C56.5609 2.46978 56.4066 2.42578 56.2491 2.42578C56.0917 2.42578 55.9373 2.46978 55.8036 2.55282C55.6698 2.63585 55.5619 2.75462 55.492 2.89572L53.602 6.72072L49.3787 7.33497C49.223 7.35759 49.0766 7.42334 48.9563 7.52478C48.8359 7.62622 48.7463 7.7593 48.6976 7.90899C48.6489 8.05867 48.6431 8.21899 48.6808 8.37183C48.7184 8.52466 48.7981 8.66392 48.9107 8.77385L51.9674 11.7517Z"
          fill="white"
        />
        <path
          d="M11.4684 11.7517L10.7472 15.9603C10.7206 16.1155 10.7378 16.2751 10.7971 16.4209C10.8564 16.5668 10.9553 16.6932 11.0826 16.7857C11.21 16.8783 11.3607 16.9334 11.5177 16.9448C11.6748 16.9562 11.8319 16.9235 11.9712 16.8502L15.7501 14.8635L19.529 16.8502C19.6684 16.9235 19.8255 16.9562 19.9825 16.9448C20.1395 16.9334 20.2902 16.8783 20.4176 16.7857C20.5449 16.6932 20.6438 16.5668 20.7031 16.4209C20.7624 16.2751 20.7797 16.1155 20.753 15.9603L20.0319 11.7517L23.0885 8.77385C23.2011 8.66392 23.2808 8.52466 23.3185 8.37183C23.3561 8.21899 23.3503 8.05867 23.3016 7.90899C23.2529 7.7593 23.1633 7.62622 23.043 7.52478C22.9226 7.42334 22.7763 7.35759 22.6205 7.33497L18.3972 6.72072L16.5072 2.89572C16.4374 2.75462 16.3294 2.63585 16.1957 2.55282C16.0619 2.46978 15.9076 2.42578 15.7501 2.42578C15.5927 2.42578 15.4383 2.46978 15.3046 2.55282C15.1708 2.63585 15.0629 2.75462 14.993 2.89572L13.103 6.72072L8.87974 7.33385C8.72374 7.35631 8.57714 7.42202 8.45658 7.52353C8.33601 7.62504 8.24628 7.7583 8.19757 7.90819C8.14885 8.05808 8.14309 8.21863 8.18095 8.37162C8.2188 8.52462 8.29875 8.66396 8.41174 8.77385L11.4684 11.7517Z"
          fill="white"
        />
        <path
          d="M41.625 45V38.25H39.6C37.269 38.2499 35.0075 37.4564 33.1875 36L30.375 38.25V45C30.375 46.4918 30.9676 47.9226 32.0225 48.9775C33.0774 50.0324 34.5082 50.625 36 50.625C37.4918 50.625 38.9226 50.0324 39.9775 48.9775C41.0324 47.9226 41.625 46.4918 41.625 45Z"
          fill="white"
        />
        <path
          d="M44.7184 52.875H44.3809C45.3189 53.8613 45.8424 55.1701 45.8434 56.5312V63.2812H50.3434V58.5C50.3434 57.7613 50.1979 57.0299 49.9152 56.3474C49.6325 55.6649 49.2182 55.0449 48.6958 54.5225C48.1735 54.0002 47.5534 53.5859 46.871 53.3032C46.1885 53.0205 45.457 52.875 44.7184 52.875Z"
          fill="white"
        />
        <path
          d="M21.6562 58.5V63.2812H26.1563V56.5312C26.1572 55.1701 26.6808 53.8613 27.6187 52.875H27.2813C25.7894 52.875 24.3587 53.4676 23.3038 54.5225C22.2489 55.5774 21.6562 57.0082 21.6562 58.5Z"
          fill="white"
        />
        <path
          d="M40.7261 52.8965C40.7553 53.0752 40.7737 53.2555 40.7812 53.4365C40.7812 54.7046 40.2775 55.9207 39.3809 56.8173C38.4842 57.714 37.2681 58.2177 36 58.2177C34.7319 58.2177 33.5158 57.714 32.6191 56.8173C31.7225 55.9207 31.2188 54.7046 31.2188 53.4365C31.227 53.2555 31.2461 53.0752 31.2761 52.8965C30.3491 52.952 29.4782 53.3587 28.8405 54.0338C28.2028 54.7089 27.8464 55.6016 27.8438 56.5302V71.7177H44.1562V56.5302C44.1534 55.602 43.7972 54.7098 43.16 54.0348C42.5229 53.3598 41.6526 52.9528 40.7261 52.8965Z"
          fill="white"
        />
        <path
          d="M43.3125 37.4062V37.7156C43.9144 37.7662 44.4944 37.9657 45 38.2961V31.7812C45 30.5878 44.5259 29.4432 43.682 28.5993C42.8381 27.7554 41.6935 27.2813 40.5 27.2813H37.3905C35.7362 27.2811 34.1046 27.6663 32.625 28.4062H31.5C30.3065 28.4062 29.1619 28.8804 28.318 29.7243C27.4741 30.5682 27 31.7128 27 32.9062V38.295C27.5145 37.9596 28.1054 37.7595 28.7179 37.7134C28.7445 37.5082 28.8464 37.3202 29.0036 37.1858L32.6599 34.2608C32.8097 34.1412 32.9958 34.0761 33.1875 34.0761C33.3792 34.0761 33.5653 34.1412 33.7151 34.2608L34.2416 34.6826C35.7626 35.8993 37.6523 36.5623 39.6 36.5625H42.4688C42.6925 36.5625 42.9071 36.6514 43.0654 36.8096C43.2236 36.9679 43.3125 37.1825 43.3125 37.4062Z"
          fill="white"
        />
        <path
          d="M36.0011 52.3109C35.237 52.3102 34.4778 52.1894 33.7511 51.9531V52.8734H32.9636C32.9267 53.0586 32.9075 53.247 32.9062 53.4359C32.9062 54.2564 33.2322 55.0433 33.8124 55.6235C34.3926 56.2037 35.1795 56.5296 36 56.5296C36.8205 56.5296 37.6074 56.2037 38.1876 55.6235C38.7678 55.0433 39.0938 54.2564 39.0938 53.4359C39.0944 53.247 39.0759 53.0585 39.0386 52.8734H38.2511V51.9531C37.5245 52.1894 36.7652 52.3102 36.0011 52.3109Z"
          fill="white"
        />
        <path
          d="M43.3125 43.2837C43.7803 43.2179 44.2086 42.9852 44.5185 42.6287C44.8285 42.2721 44.9991 41.8155 44.9991 41.3431C44.9991 40.8706 44.8285 40.4141 44.5185 40.0575C44.2086 39.7009 43.7803 39.4683 43.3125 39.4025V43.2837Z"
          fill="white"
        />
        <path
          d="M28.6866 43.2837V39.4025C28.2188 39.4683 27.7905 39.7009 27.4806 40.0575C27.1707 40.4141 27 40.8706 27 41.3431C27 41.8155 27.1707 42.2721 27.4806 42.6287C27.7905 42.9852 28.2188 43.2179 28.6866 43.2837Z"
          fill="white"
        />
        <path
          d="M20.25 38.25V31.5C18.0768 31.1378 16.1138 29.9861 14.7375 28.2656L14.625 28.125L14.5125 28.2656C13.1362 29.9861 11.1732 31.1378 9 31.5V38.25C9 39.7418 9.59263 41.1726 10.6475 42.2275C11.7024 43.2824 13.1332 43.875 14.625 43.875C16.1168 43.875 17.5476 43.2824 18.6025 42.2275C19.6574 41.1726 20.25 39.7418 20.25 38.25Z"
          fill="white"
        />
        <path
          d="M7.3125 30.7856C7.31246 30.5858 7.38333 30.3925 7.51251 30.24C7.64168 30.0876 7.82076 29.9859 8.01787 29.9531L8.73788 29.8327C10.4963 29.5393 12.084 28.6056 13.1951 27.2115L13.9646 26.2451C14.0438 26.146 14.1442 26.0659 14.2585 26.0108C14.3729 25.9558 14.4981 25.9272 14.625 25.9272C14.7519 25.9272 14.8771 25.9558 14.9915 26.0108C15.1058 26.0659 15.2062 26.146 15.2854 26.2451L16.056 27.2138C17.1648 28.6066 18.7504 29.5392 20.5065 29.8316L21.2321 29.9531C21.4292 29.9859 21.6083 30.0876 21.7375 30.24C21.8667 30.3925 21.9375 30.5858 21.9375 30.7856V30.9656C22.5394 31.0162 23.1194 31.2157 23.625 31.5461V27.2812C23.625 25.491 22.9138 23.7741 21.648 22.5083C20.3821 21.2424 18.6652 20.5313 16.875 20.5313H12.375C10.5848 20.5312 8.8679 21.2424 7.60203 22.5083C6.33616 23.7741 5.625 25.491 5.625 27.2812V31.5461C6.13065 31.2157 6.71056 31.0162 7.3125 30.9656V30.7856Z"
          fill="white"
        />
        <path
          d="M21.9375 32.6525V36.5337C22.4053 36.4679 22.8336 36.2352 23.1435 35.8787C23.4535 35.5221 23.6241 35.0655 23.6241 34.5931C23.6241 34.1206 23.4535 33.6641 23.1435 33.3075C22.8336 32.9509 22.4053 32.7183 21.9375 32.6525Z"
          fill="white"
        />
        <path
          d="M7.31163 36.5337V32.6525C6.8438 32.7183 6.4155 32.9509 6.10558 33.3075C5.79567 33.6641 5.625 34.1206 5.625 34.5931C5.625 35.0655 5.79567 35.5221 6.10558 35.8787C6.4155 36.2352 6.8438 36.4679 7.31163 36.5337Z"
          fill="white"
        />
        <path
          d="M63 38.25V31.5H60.975C58.644 31.4999 56.3825 30.7064 54.5625 29.25L51.75 31.5V38.25C51.75 39.7418 52.3426 41.1726 53.3975 42.2275C54.4524 43.2824 55.8832 43.875 57.375 43.875C58.8668 43.875 60.2976 43.2824 61.3525 42.2275C62.4074 41.1726 63 39.7418 63 38.25Z"
          fill="white"
        />
        <path
          d="M67.2188 64.9688H70.875C71.0988 64.9688 71.3134 64.8799 71.4716 64.7216C71.6299 64.5634 71.7188 64.3488 71.7188 64.125V58.2188H67.2188V64.9688Z"
          fill="white"
        />
        <path
          d="M66.0934 46.125H65.7559C66.6939 47.1113 67.2174 48.4201 67.2184 49.7812V56.5312H71.7184V51.75C71.7184 50.2582 71.1257 48.8274 70.0708 47.7725C69.0159 46.7176 67.5852 46.125 66.0934 46.125Z"
          fill="white"
        />
        <path
          d="M57.3761 45.5609C56.612 45.5602 55.8528 45.4394 55.1261 45.2031V46.1234H54.3386C54.3017 46.3086 54.2825 46.497 54.2812 46.6859C54.2813 47.5064 54.6072 48.2933 55.1874 48.8735C55.7676 49.4537 56.5545 49.7796 57.375 49.7796C58.1955 49.7796 58.9824 49.4537 59.5626 48.8735C60.1428 48.2933 60.4688 47.5064 60.4688 46.6859C60.4694 46.497 60.4509 46.3085 60.4136 46.1234H59.6261V45.2031C58.8995 45.4394 58.1402 45.5602 57.3761 45.5609Z"
          fill="white"
        />
        <path
          d="M64.6875 36.5337C65.1553 36.4679 65.5836 36.2352 65.8935 35.8787C66.2035 35.5221 66.3741 35.0655 66.3741 34.5931C66.3741 34.1206 66.2035 33.6641 65.8935 33.3075C65.5836 32.9509 65.1553 32.7183 64.6875 32.6525V36.5337Z"
          fill="white"
        />
        <path
          d="M50.0616 36.5337V32.6525C49.5938 32.7183 49.1655 32.9509 48.8556 33.3075C48.5457 33.6641 48.375 34.1206 48.375 34.5931C48.375 35.0655 48.5457 35.5221 48.8556 35.8787C49.1655 36.2352 49.5938 36.4679 50.0616 36.5337Z"
          fill="white"
        />
        <path
          d="M50.3786 30.4358L54.0349 27.5108C54.1847 27.3912 54.3708 27.3261 54.5625 27.3261C54.7542 27.3261 54.9403 27.3912 55.0901 27.5108L55.6166 27.9326C57.1376 29.1493 59.0273 29.8123 60.975 29.8125H63.8438C64.0675 29.8125 64.2821 29.9014 64.4404 30.0596C64.5986 30.2179 64.6875 30.4325 64.6875 30.6562V30.9656C65.2894 31.0162 65.8694 31.2157 66.375 31.5461V29.5312C66.375 27.1443 65.4268 24.8551 63.739 23.1673C62.0511 21.4795 59.7619 20.5313 57.375 20.5312C54.9881 20.5313 52.6989 21.4795 51.011 23.1673C49.3232 24.8551 48.375 27.1443 48.375 29.5312V31.545C48.8895 31.2096 49.4804 31.0095 50.0929 30.9634C50.1195 30.7582 50.2214 30.5702 50.3786 30.4358Z"
          fill="white"
        />
        <path
          d="M27.2809 51.1875H28.9121C28.7771 49.8033 28.1324 48.5187 27.1033 47.5831C26.0742 46.6476 24.7341 46.1279 23.3434 46.125H23.0059C23.9439 47.1113 24.4674 48.4201 24.4684 49.7812V51.75C25.3592 51.3776 26.3153 51.1864 27.2809 51.1875Z"
          fill="white"
        />
        <path
          d="M22.7812 52.7488V49.7822C22.7784 48.8538 22.422 47.9614 21.7846 47.2864C21.1472 46.6114 20.2767 46.2045 19.35 46.1484C19.3792 46.3272 19.3976 46.5075 19.4051 46.6884C19.4051 47.9565 18.9014 49.1726 18.0047 50.0693C17.1081 50.9659 15.8919 51.4697 14.6239 51.4697C13.3558 51.4697 12.1397 50.9659 11.243 50.0693C10.3464 49.1726 9.84262 47.9565 9.84262 46.6884C9.85086 46.5074 9.87002 46.3271 9.9 46.1484C8.97322 46.2042 8.10252 46.611 7.46508 47.2861C6.82764 47.9612 6.47136 48.8537 6.46875 49.7822V64.9697H19.9688V58.5009C19.9695 57.3914 20.2231 56.2967 20.7105 55.3C21.1978 54.3032 21.906 53.4307 22.7812 52.7488Z"
          fill="white"
        />
        <path
          d="M47.5316 51.75V49.7812C47.5326 48.4201 48.0561 47.1113 48.9941 46.125H48.6566C47.2659 46.1279 45.9258 46.6476 44.8967 47.5831C43.8676 48.5187 43.2229 49.8033 43.0879 51.1875H44.7191C45.6847 51.1864 46.6408 51.3776 47.5316 51.75Z"
          fill="white"
        />
        <path
          d="M62.1011 46.1465C62.1303 46.3252 62.1487 46.5055 62.1563 46.6865C62.1563 47.9546 61.6525 49.1707 60.7559 50.0673C59.8592 50.964 58.6431 51.4677 57.375 51.4677C56.1069 51.4677 54.8908 50.964 53.9941 50.0673C53.0975 49.1707 52.5938 47.9546 52.5938 46.6865C52.602 46.5055 52.6211 46.3252 52.6511 46.1465C51.7241 46.202 50.8532 46.6087 50.2155 47.2838C49.5778 47.9589 49.2214 48.8516 49.2188 49.7802V52.7469C50.0942 53.4287 50.8026 54.3011 51.2901 55.2978C51.7777 56.2946 52.0315 57.3894 52.0324 58.499V64.9677H65.5324V49.7802C65.5295 48.8519 65.1731 47.9594 64.5357 47.2844C63.8984 46.6094 63.0278 46.2025 62.1011 46.1465Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_9616_51779">
          <rect width="72" height="72" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default TeamIcon;
