import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IIconProperty } from 'types/iconProps';

const P2PPrizePool = ({ width, height, color }: IIconProperty) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 60 60"
      style={{ transform: 'scale(1)', width, height }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.4996 8.57031C26.2609 8.57031 22.1174 9.82723 18.5931 12.1821C15.0687 14.537 12.3218 17.8841 10.6997 21.8002C9.07765 25.7162 8.65324 30.0253 9.48016 34.1826C10.3071 38.3398 12.3482 42.1585 15.3454 45.1557C18.3427 48.153 22.1613 50.1941 26.3186 51.021C30.4758 51.8479 34.785 51.4235 38.701 49.8015C42.6171 48.1794 45.9642 45.4325 48.3191 41.9081C50.674 38.3838 51.9309 34.2403 51.9309 30.0016C51.9309 27.1872 51.3765 24.4003 50.2995 21.8002C49.2225 19.2 47.6439 16.8375 45.6538 14.8474C43.6637 12.8573 41.3012 11.2787 38.701 10.2017C36.1008 9.12465 33.314 8.57031 30.4996 8.57031ZM37.9996 36.4328C37.9947 36.9982 37.768 37.539 37.3681 37.9388C36.9683 38.3386 36.4275 38.5654 35.8621 38.5703H32.6371V40.7078H28.3621V38.5703H24.0684V34.2953H33.7621V32.1391H25.0621C24.5083 32.1196 23.9837 31.8859 23.5989 31.4871C23.2142 31.0883 22.9993 30.5557 22.9996 30.0016V23.5703C22.9971 23.2889 23.0507 23.0098 23.1572 22.7494C23.2638 22.4889 23.4211 22.2523 23.6201 22.0533C23.8191 21.8543 24.0557 21.697 24.3162 21.5904C24.5766 21.4839 24.8557 21.4303 25.1371 21.4328H28.4371V19.2953H32.7309V21.4328H37.0246V25.7266H27.1996V27.8641H35.8996C36.46 27.8739 36.9941 28.1034 37.3869 28.5032C37.7797 28.903 37.9997 29.4411 37.9996 30.0016V36.4328Z"
        fill="#BDBDBB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.5002 53.568C60.5002 54.84 60.123 56.0834 59.4163 57.141C58.7097 58.1986 57.7052 59.0229 56.5301 59.5097C55.3549 59.9964 54.0618 60.1238 52.8143 59.8756C51.5667 59.6275 50.4208 59.015 49.5214 58.1156C48.6219 57.2161 48.0094 56.0702 47.7613 54.8226C47.5131 53.5751 47.6405 52.282 48.1272 51.1068C48.614 49.9317 49.4383 48.9273 50.4959 48.2206C51.5536 47.5139 52.797 47.1367 54.0689 47.1367C55.7746 47.1367 57.4104 47.8143 58.6165 49.0204C59.8226 50.2265 60.5002 51.8623 60.5002 53.568Z"
        fill="#BDBDBB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3625 6.43125C13.3625 7.70323 12.9853 8.94665 12.2786 10.0043C11.572 11.0619 10.5675 11.8862 9.39239 12.3729C8.21723 12.8597 6.92412 12.9871 5.67658 12.7389C4.42904 12.4908 3.2831 11.8783 2.38367 10.9788C1.48425 10.0794 0.871729 8.93347 0.623578 7.68592C0.375426 6.43838 0.502786 5.14527 0.989552 3.97012C1.47632 2.79496 2.30063 1.79054 3.35824 1.08386C4.41586 0.377186 5.65927 0 6.93125 0C8.63693 0 10.2727 0.677576 11.4788 1.88367C12.6849 3.08976 13.3625 4.72558 13.3625 6.43125Z"
        fill="#BDBDBB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.5002 6.43125C60.5002 7.70323 60.123 8.94665 59.4163 10.0043C58.7097 11.0619 57.7052 11.8862 56.5301 12.3729C55.3549 12.8597 54.0618 12.9871 52.8143 12.7389C51.5667 12.4908 50.4208 11.8783 49.5214 10.9788C48.6219 10.0794 48.0094 8.93347 47.7613 7.68592C47.5131 6.43838 47.6405 5.14527 48.1272 3.97012C48.614 2.79496 49.4383 1.79054 50.4959 1.08386C51.5536 0.377186 52.797 0 54.0689 0C55.7746 0 57.4104 0.677576 58.6165 1.88367C59.8226 3.08976 60.5002 4.72558 60.5002 6.43125Z"
        fill="#BDBDBB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3625 53.568C13.3625 54.84 12.9853 56.0834 12.2786 57.141C11.572 58.1986 10.5675 59.0229 9.39239 59.5097C8.21723 59.9964 6.92412 60.1238 5.67658 59.8756C4.42904 59.6275 3.2831 59.015 2.38367 58.1156C1.48425 57.2161 0.871729 56.0702 0.623578 54.8226C0.375426 53.5751 0.502786 52.282 0.989552 51.1068C1.47632 49.9317 2.30063 48.9273 3.35824 48.2206C4.41586 47.5139 5.65927 47.1367 6.93125 47.1367C8.63693 47.1367 10.2727 47.8143 11.4788 49.0204C12.6849 50.2265 13.3625 51.8623 13.3625 53.568Z"
        fill="#BDBDBB"
      />
    </SvgIcon>
  );
};

export default P2PPrizePool;
