import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IIconProperty } from 'types/iconProps';

const Profile2UsersIcon = ({ width, height, color }: IIconProperty) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 36 36"
      style={{ transform: 'scale(1)', width, height }}
    >
      <path
        d="M13.5 3C9.57 3 6.375 6.195 6.375 10.125C6.375 13.98 9.39 17.1 13.32 17.235C13.44 17.22 13.56 17.22 13.65 17.235C13.68 17.235 13.695 17.235 13.725 17.235C13.74 17.235 13.74 17.235 13.755 17.235C17.595 17.1 20.61 13.98 20.625 10.125C20.625 6.195 17.43 3 13.5 3Z"
        fill="#BDBDBD"
      />
      <path
        d="M21.1199 21.2249C16.9349 18.4349 10.1099 18.4349 5.89494 21.2249C3.98994 22.4999 2.93994 24.2249 2.93994 26.0699C2.93994 27.9149 3.98994 29.6249 5.87994 30.8849C7.97994 32.2949 10.7399 32.9999 13.4999 32.9999C16.2599 32.9999 19.0199 32.2949 21.1199 30.8849C23.0099 29.6099 24.0599 27.8999 24.0599 26.0399C24.0449 24.1949 23.0099 22.4849 21.1199 21.2249Z"
        fill="#BDBDBD"
      />
      <path
        d="M29.985 11.01C30.225 13.92 28.155 16.47 25.29 16.815C25.275 16.815 25.275 16.815 25.26 16.815H25.215C25.125 16.815 25.035 16.815 24.96 16.845C23.505 16.92 22.17 16.455 21.165 15.6C22.71 14.22 23.595 12.15 23.415 9.90003C23.31 8.68503 22.89 7.57503 22.26 6.63003C22.83 6.34503 23.49 6.16503 24.165 6.10503C27.105 5.85003 29.73 8.04003 29.985 11.01Z"
        fill="#BDBDBD"
      />
      <path
        d="M32.9849 24.885C32.8649 26.34 31.9349 27.6 30.3749 28.455C28.8749 29.28 26.9849 29.67 25.1099 29.625C26.1899 28.65 26.8199 27.435 26.9399 26.145C27.0899 24.285 26.2049 22.5 24.4349 21.075C23.4299 20.28 22.2599 19.65 20.9849 19.185C24.2999 18.225 28.4699 18.87 31.0349 20.94C32.4149 22.05 33.1199 23.445 32.9849 24.885Z"
        fill="#BDBDBD"
      />
    </SvgIcon>
  );
};

export default Profile2UsersIcon;
