import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

import { IIconProperty } from 'types/iconProps';

const CameraIcon = ({ width, height, color }: IIconProperty) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 20 20"
      style={{ transform: 'scale(1)', width, height }}
    >
      <path
        d="M13.3437 4.48054L13.3436 4.48058L13.3476 4.48833C13.6645 5.09955 14.2997 5.49984 14.9998 5.49984C16.5206 5.49984 17.7238 6.78209 17.6259 8.2925L17.6258 8.29342L17.1925 15.1767C17.1925 15.1767 17.1925 15.1767 17.1925 15.1768C17.1429 15.9617 16.9118 16.6174 16.4858 17.0728C16.0693 17.5179 15.4089 17.8332 14.3665 17.8332H5.63315C4.59049 17.8332 3.92792 17.5178 3.51042 17.0725C3.08371 16.6173 2.85292 15.9623 2.80731 15.1791L2.80716 15.1768L2.37383 8.29342L2.37377 8.2925C2.27587 6.78209 3.47901 5.49984 4.99981 5.49984C5.69997 5.49984 6.33509 5.09955 6.65202 4.48833L6.65209 4.48836L6.65598 4.48054L7.25513 3.27392C7.39651 2.99513 7.66878 2.71283 8.01418 2.49884C8.36019 2.28447 8.7346 2.1665 9.04981 2.1665H10.9581C11.2684 2.1665 11.6405 2.28394 11.986 2.49853C12.3307 2.71263 12.6031 2.99505 12.7445 3.27393C12.7448 3.27442 12.745 3.27491 12.7453 3.2754L13.3437 4.48054ZM11.2498 5.5415H8.74981C8.13201 5.5415 7.62481 6.04869 7.62481 6.6665C7.62481 7.28431 8.13201 7.7915 8.74981 7.7915H11.2498C11.8676 7.7915 12.3748 7.28431 12.3748 6.6665C12.3748 6.0487 11.8676 5.5415 11.2498 5.5415ZM6.68315 12.2832C6.68315 14.1186 8.17464 15.5998 9.99981 15.5998C11.825 15.5998 13.3165 14.1186 13.3165 12.2832C13.3165 10.4487 11.8343 8.9665 9.99981 8.9665C8.16534 8.9665 6.68315 10.4487 6.68315 12.2832Z"
        fill="#BDBDBD"
        stroke="#BDBDBD"
      />
    </SvgIcon>
  );
};

export default CameraIcon;
