import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IIconProperty } from 'types/iconProps';

const TelegramIcon2 = ({ width, height, color }: IIconProperty) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 36 36"
      style={{ transform: 'scale(1)', width, height }}
    >
      <g clipPath="url(#clip0_7558_27896)">
        <path
          d="M18.014 36.0281C27.9629 36.0281 36.0281 27.9629 36.0281 18.014C36.0281 8.06515 27.9629 0 18.014 0C8.06515 0 0 8.06515 0 18.014C0 27.9629 8.06515 36.0281 18.014 36.0281Z"
          fill="#0B0B0E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.13723 17.8168C13.3843 15.516 16.8917 14.0288 18.6594 13.2993C23.654 11.2229 24.6922 10.8581 25.3656 10.8301C25.5059 10.8301 25.8426 10.8581 26.0671 11.0265C26.2354 11.1668 26.2916 11.3632 26.3196 11.5035C26.3477 11.6438 26.3757 11.9524 26.3477 12.205C26.0671 15.039 24.9167 21.9696 24.2993 25.1403C24.0468 26.4871 23.5417 26.9361 23.0647 26.9922C22.0265 27.0764 21.2128 26.2907 20.2027 25.6454C18.6314 24.6072 17.7335 23.9618 16.1902 22.9517C14.4225 21.7732 15.5729 21.1278 16.5831 20.0896C16.8356 19.809 21.4654 15.6282 21.5495 15.2354C21.5495 15.1793 21.5776 15.0109 21.4654 14.9267C21.3531 14.8425 21.2128 14.8706 21.1006 14.8987C20.9322 14.9267 18.4069 16.6103 13.4965 19.9213C12.767 20.4263 12.1216 20.6508 11.5324 20.6508C10.887 20.6508 9.65243 20.286 8.72647 19.9774C7.6041 19.6126 6.70621 19.4162 6.79038 18.7989C6.8465 18.4902 7.29545 18.1535 8.13723 17.8168Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_7558_27896">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default TelegramIcon2;
