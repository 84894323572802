import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IIconProperty } from 'types/iconProps';

const WalletConnectIcon = ({ width, height, color }: IIconProperty) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 23 15"
      style={{ transform: 'scale(1)', width, height }}
    >
      <path
        d="M4.71032 3.24386C8.46019 -0.380114 14.5399 -0.380114 18.2898 3.24386L18.7411 3.68001C18.9286 3.86121 18.9286 4.15499 18.7411 4.33618L17.1973 5.82817C17.1035 5.91877 16.9515 5.91877 16.8578 5.82817L16.2367 5.22797C13.6207 2.6998 9.37936 2.6998 6.76337 5.22797L6.09828 5.87073C6.00453 5.96133 5.85254 5.96133 5.75879 5.87073L4.21498 4.37875C4.02748 4.19755 4.02748 3.90377 4.21498 3.72257L4.71032 3.24386ZM21.4825 6.32944L22.8565 7.65731C23.044 7.83851 23.044 8.13229 22.8565 8.31349L16.6611 14.301C16.4736 14.4822 16.1696 14.4822 15.9821 14.301C15.9821 14.301 15.9821 14.301 15.9821 14.301L11.585 10.0515C11.5381 10.0062 11.4621 10.0062 11.4152 10.0515C11.4152 10.0515 11.4152 10.0515 11.4152 10.0515L7.01815 14.301C6.83066 14.4822 6.52667 14.4822 6.33917 14.301C6.33917 14.301 6.33917 14.301 6.33917 14.301L0.143549 8.31341C-0.0439436 8.13221 -0.0439436 7.83843 0.143549 7.65723L1.51755 6.32936C1.70504 6.14816 2.00903 6.14816 2.19652 6.32936L6.59374 10.579C6.64061 10.6243 6.71661 10.6243 6.76348 10.579C6.76348 10.579 6.76348 10.579 6.76348 10.579L11.1605 6.32936C11.348 6.14816 11.652 6.14815 11.8395 6.32935C11.8395 6.32935 11.8395 6.32935 11.8395 6.32935L16.2367 10.579C16.2835 10.6243 16.3595 10.6243 16.4064 10.579L20.8036 6.32944C20.9911 6.14824 21.295 6.14824 21.4825 6.32944Z"
        fill="#3B99FC"
      />
    </SvgIcon>
  );
};

export default WalletConnectIcon;
