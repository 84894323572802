import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IIconProperty } from 'types/iconProps';

const MMAIcon = ({ width, height, color }: IIconProperty) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 20 20"
      style={{ transform: 'scale(1)', width, height }}
    >
      <path
        d="M11.4894 0H9.68246C6.30356 0 3.55469 2.78793 3.55469 6.16684C3.55469 6.95969 3.85162 7.67761 4.32556 8.24219H8.24219C9.10599 8.24219 9.85352 8.71689 10.26 9.41406H11.1719C12.7899 9.41406 14.1016 8.10242 14.1016 6.48438V5.89844H15.2734V6.48438C15.2734 8.74954 13.437 10.5859 11.1719 10.5859H10.5859C10.5859 11.8785 9.53476 12.9297 8.24219 12.9297H5.89844V11.7578H8.24219C8.88824 11.7578 9.41406 11.232 9.41406 10.5859C9.41406 9.93988 8.88824 9.41406 8.24219 9.41406H4.62601C3.38715 9.41406 2.38281 10.4184 2.38281 11.6573C2.38281 12.2522 2.61917 12.8227 3.03986 13.2433L5.06989 15.2734H15.7233C16.9597 13.0928 17.6172 10.643 17.6172 8.11859V6.16684C17.6172 2.78244 14.8737 0 11.4894 0Z"
        fill={color}
      />
      <path
        d="M5.89844 16.4453V20H10.015C9.6431 19.5088 9.41406 18.9043 9.41406 18.2422C9.41406 17.5801 9.6431 16.9365 10.015 16.4453H5.89844Z"
        fill={color}
      />
      <path
        d="M10.5859 18.2422C10.5859 19.213 11.373 20 12.3438 20H15.2734V16.4453H12.3438C11.373 16.4453 10.5859 17.2714 10.5859 18.2422Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default MMAIcon;
