import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IIconProperty } from 'types/iconProps';

const TwitterIcon2 = ({ width, height, color }: IIconProperty) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 36 36"
      style={{ transform: 'scale(1)', width, height }}
    >
      <g clipPath="url(#clip0_7558_27890)">
        <path
          d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05888 27.9411 0 18 0C8.05888 0 0 8.05888 0 18C0 27.9411 8.05888 36 18 36Z"
          fill="#0B0B0E"
        />
        <path
          d="M29.6598 10.77C28.7827 11.153 27.855 11.4075 26.9053 11.5255C27.9065 10.9318 28.6549 9.9913 29.0085 8.88237C28.0714 9.43856 27.0461 9.83027 25.9769 10.0405C25.3217 9.33983 24.4708 8.85251 23.5349 8.64195C22.599 8.43138 21.6214 8.50733 20.7292 8.8599C19.8371 9.21247 19.0717 9.82535 18.5325 10.6188C17.9934 11.4123 17.7055 12.3496 17.7064 13.3089C17.703 13.6751 17.7403 14.0405 17.8177 14.3984C15.9158 14.305 14.055 13.8114 12.3568 12.9499C10.6586 12.0884 9.16133 10.8783 7.96268 9.39868C7.34655 10.4512 7.15567 11.6993 7.42905 12.8879C7.70242 14.0765 8.41941 15.1158 9.43347 15.7934C8.67657 15.773 7.93571 15.5707 7.27346 15.2037V15.2558C7.2756 16.3591 7.65747 17.428 8.35491 18.2828C9.05235 19.1377 10.0228 19.7264 11.1032 19.95C10.6943 20.0574 10.273 20.11 9.85031 20.1063C9.54593 20.1119 9.24183 20.0849 8.9432 20.0258C9.25272 20.9746 9.84863 21.8043 10.6489 22.4006C11.4493 22.9969 12.4147 23.3306 13.4124 23.3558C11.7217 24.6773 9.63733 25.3952 7.49136 25.395C7.109 25.3983 6.72684 25.3761 6.34741 25.3287C8.53553 26.7395 11.0861 27.4849 13.6895 27.4745C22.4882 27.4745 27.2985 20.1868 27.2985 13.8703C27.2985 13.6595 27.2985 13.4558 27.2819 13.2521C28.2187 12.576 29.0245 11.7349 29.6598 10.77Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_7558_27890">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default TwitterIcon2;
