import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

import { IIconProperty } from 'types/iconProps';

const GameFiIcon = ({ width, height, color }: IIconProperty) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 20 20"
      style={{ transform: 'scale(1)', width, height }}
    >
      <path
        d="M6.14578 10.8716L6.35422 10.6388C5.78111 10.1255 5.32257 9.49718 5.00848 8.79484C4.69438 8.0925 4.53178 7.33187 4.53125 6.5625C4.53125 3.54702 6.98452 1.09375 10 1.09375C13.0155 1.09375 15.4688 3.54702 15.4688 6.5625C15.4682 7.33187 15.3056 8.0925 14.9915 8.79484C14.6774 9.49718 14.2189 10.1255 13.6458 10.6388L13.8542 10.8716C14.1956 10.5661 14.4993 10.221 14.7592 9.84375H15.9384C15.567 10.3384 15.1147 10.7666 14.6006 11.1104L14.7744 11.3701C15.5653 10.8422 16.2137 10.1271 16.6619 9.28847C17.1101 8.4498 17.3443 7.51343 17.3438 6.5625C17.3438 3.37471 14.7503 0.78125 11.5625 0.78125H9.6875V0.789769C6.64446 0.952666 4.21875 3.47956 4.21875 6.5625C4.21929 7.37582 4.39118 8.17989 4.7232 8.92235C5.05523 9.66481 5.53995 10.329 6.14578 10.8716ZM16.1557 9.53125H14.9594C15.1945 9.13814 15.3823 8.71855 15.5187 8.28125H16.7544C16.6088 8.72167 16.4076 9.14172 16.1557 9.53125ZM16.8474 7.96875H15.6067C15.7097 7.55964 15.7675 7.14047 15.7791 6.71875H17.0288C17.0168 7.14102 16.9559 7.56048 16.8474 7.96875ZM17.0289 6.40625H15.7791C15.7679 5.98458 15.7106 5.5654 15.6079 5.15625H16.8476C16.9563 5.56449 17.0171 5.98397 17.0289 6.40625ZM16.7543 4.84375H15.5204C15.3837 4.4062 15.1952 3.98656 14.9589 3.59375H16.1532C16.406 3.98311 16.608 4.40318 16.7543 4.84375ZM15.9347 3.28125H14.7571C14.5216 2.94092 14.2505 2.62665 13.9484 2.34375H15.0387C15.3732 2.61993 15.6739 2.93461 15.9347 3.28125ZM14.6213 2.03125H13.5862C13.082 1.63112 12.5146 1.31804 11.9073 1.10493C12.8785 1.16517 13.8158 1.48508 14.6213 2.03125Z"
        fill={color}
      />
      <path
        d="M13.884 8.89781C14.2545 8.28112 14.473 7.58509 14.5213 6.86725C14.5695 6.14941 14.4463 5.43039 14.1616 4.76963C13.877 4.10887 13.4392 3.52535 12.8844 3.06731C12.3296 2.60926 11.6737 2.28986 10.971 2.1355L10.9043 2.44081C11.5585 2.58461 12.169 2.88202 12.6855 3.30848C13.202 3.73493 13.6095 4.27817 13.8745 4.89331C14.1395 5.50845 14.2543 6.17781 14.2094 6.84609C14.1645 7.51437 13.9612 8.16236 13.6163 8.73653L13.884 8.89781Z"
        fill={color}
      />
      <path
        d="M9.09584 2.44079L9.02916 2.1355C8.30144 2.29532 7.62433 2.63206 7.05777 3.11593C6.4912 3.5998 6.05265 4.21587 5.78093 4.90962C5.50921 5.60338 5.41269 6.35341 5.49992 7.09336C5.58716 7.8333 5.85545 8.54033 6.28105 9.15187L6.53732 8.97308C6.14116 8.4037 5.89145 7.74546 5.81027 7.05659C5.7291 6.36772 5.81898 5.66947 6.07194 5.0236C6.32491 4.37774 6.73317 3.80419 7.2606 3.35369C7.78803 2.9032 8.41837 2.58965 9.09584 2.44079Z"
        fill={color}
      />
      <path
        d="M4.00019 2.58173L3.7337 2.41846C3.60731 2.62474 3.4899 2.8396 3.38477 3.05708L3.66606 3.19311C3.76677 2.98492 3.87915 2.77921 4.00019 2.58173Z"
        fill={color}
      />
      <path
        d="M2.96875 6.2499C2.96759 5.41516 3.11556 4.58693 3.40569 3.80424L3.11272 3.69556C2.72748 4.73784 2.58325 5.85382 2.69083 6.9598C2.79841 8.06577 3.15502 9.13303 3.7339 10.0815L4.00032 9.91826C3.32465 8.81405 2.96762 7.54443 2.96875 6.2499Z"
        fill={color}
      />
      <path
        d="M12.2422 5.43135L10.1276 2.41039C10.1131 2.38981 10.094 2.37301 10.0717 2.36141C10.0494 2.34981 10.0247 2.34375 9.99953 2.34375C9.97441 2.34375 9.94966 2.34981 9.92737 2.36141C9.90509 2.37301 9.88592 2.38981 9.87151 2.41039L7.75684 5.43135L9.99953 6.3925L12.2422 5.43135Z"
        fill={color}
      />
      <path
        d="M9.99963 8.90624C10.0248 8.90624 10.0495 8.90018 10.0718 8.88858C10.0941 8.87698 10.1132 8.86018 10.1277 8.8396L12.2904 5.75L10.0612 6.70611C10.0417 6.71444 10.0208 6.71874 9.99963 6.71874C9.97848 6.71874 9.95755 6.71444 9.9381 6.70611L7.70898 5.75013L9.87161 8.8396C9.88602 8.86018 9.90518 8.87698 9.92747 8.88858C9.94975 8.90018 9.97451 8.90624 9.99963 8.90624Z"
        fill={color}
      />
      <path
        d="M14.7091 13.0087C14.554 12.5418 14.2556 12.1357 13.8565 11.848C13.4573 11.5603 12.9777 11.4057 12.4857 11.4061H11.4061V10.3771L12.9228 8.86039L12.7018 8.6394L11.1393 10.2019C11.11 10.2312 11.0936 10.271 11.0936 10.3124V11.4061H10.1561V9.6874H9.84356V11.4061H8.90606V10.3124C8.90606 10.271 8.88959 10.2312 8.86029 10.2019L7.29779 8.6394L7.07684 8.86039L8.59356 10.3771V11.4061H7.51397C7.02195 11.4057 6.5423 11.5603 6.14315 11.848C5.74401 12.1357 5.44566 12.5418 5.29049 13.0087L3.97274 16.962C3.83561 17.3734 3.85948 17.8215 4.03955 18.216C4.21961 18.6106 4.5425 18.9222 4.94316 19.0881C5.34382 19.2541 5.79249 19.2621 6.19879 19.1104C6.60508 18.9588 6.93882 18.6588 7.13276 18.2709L7.42607 17.6843C7.66506 17.2063 8.03243 16.8044 8.48702 16.5234C8.9416 16.2425 9.46544 16.0936 9.99984 16.0936C10.5342 16.0936 11.0581 16.2425 11.5127 16.5234C11.9672 16.8044 12.3346 17.2064 12.5736 17.6843L12.8669 18.2709C13.0608 18.6588 13.3945 18.9588 13.8008 19.1104C14.2071 19.2621 14.6558 19.2541 15.0565 19.0882C15.4571 18.9222 15.78 18.6106 15.9601 18.216C16.1402 17.8215 16.164 17.3734 16.0269 16.962L14.7091 13.0087ZM8.74981 14.2186H7.65606V15.3124H7.34356V14.2186H6.24981V13.9061H7.34356V12.8124H7.65606V13.9061H8.74981V14.2186ZM11.8748 14.2186H11.2498V13.9061H11.8748V14.2186ZM12.8123 15.1561H12.1873V14.8436H12.8123V15.1561ZM12.8123 13.2811H12.1873V12.9686H12.8123V13.2811ZM13.7498 14.2186H13.1248V13.9061H13.7498V14.2186Z"
        fill={color}
      />
    </SvgIcon>
  );
};
export default GameFiIcon;
