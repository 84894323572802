import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IIconProperty } from 'types/iconProps';

const BasketballIcon = ({ width, height, color }: IIconProperty) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 20 20"
      style={{ transform: 'scale(1)', width, height }}
    >
      <path
        d="M10.8142 1.61554C11.0404 1.31573 11.004 0.679815 10.9718 0.118853C10.9706 0.0946733 10.9693 0.0710254 10.9679 0.0470093C10.6508 0.0167335 10.3299 0 10.005 0C8.76022 0 7.56916 0.229319 6.46986 0.645039C7.25404 1.02189 8.4679 1.67053 9.81713 2.62892C10.2775 2.23276 10.6247 1.86704 10.8142 1.61554Z"
        fill={color}
      />
      <path
        d="M15.881 7.32151C15.3881 7.37719 14.9725 7.45808 14.617 7.57153C16.3164 10.5386 16.979 14.2872 17.1411 17.0029C18.8247 15.2875 19.8939 12.9685 19.995 10.3998C18.8349 8.97391 17.2981 7.16076 15.881 7.32151Z"
        fill={color}
      />
      <path
        d="M5.77826 4.76064C6.97231 4.51218 8.09412 3.9112 8.98856 3.27684C7.12259 1.99168 5.56625 1.32471 5.25666 1.19775C3.32077 2.24427 1.77163 3.91169 0.870388 5.93203C2.04182 5.46529 3.3105 5.22783 4.55659 4.99647C4.95971 4.9216 5.37641 4.84423 5.77826 4.76064Z"
        fill={color}
      />
      <path
        d="M12.6901 5.05116C12.7967 5.15827 12.903 5.26722 13.0085 5.37822C13.3981 5.78715 13.7515 6.23441 14.0756 6.70762C14.5477 6.5294 15.1033 6.40302 15.7685 6.32782C17.3358 6.15054 18.7294 7.39205 19.9254 8.75888C19.669 6.68683 18.7803 4.81149 17.4562 3.33252C16.7334 3.41962 15.9276 3.5672 15.1063 3.81227C14.5676 3.97318 13.7096 4.41038 12.6901 5.05116Z"
        fill={color}
      />
      <path
        d="M11.3743 10.2153C11.7845 8.82613 12.2596 7.82469 13.18 7.17466C12.9038 6.77809 12.6065 6.4059 12.2845 6.06779C12.1355 5.91134 11.9849 5.76025 11.8336 5.61182C8.14063 8.12549 3.08199 12.743 2.62201 16.7437C4.15748 18.4237 6.25887 19.5758 8.62447 19.9027C9.48582 18.1878 10.2965 14.7016 10.7784 12.6283C11.0099 11.6324 11.2097 10.7725 11.3743 10.2153Z"
        fill={color}
      />
      <path
        d="M7.18715 8.01271C8.47478 6.82619 9.83457 5.76219 11.0783 4.90707C10.6637 4.54028 10.2479 4.20217 9.83964 3.89209C8.80663 4.66862 7.45411 5.43346 5.98189 5.73965C5.56961 5.82545 5.14742 5.90388 4.73927 5.97965C3.16665 6.27169 1.65703 6.55452 0.387489 7.26285C0.14025 8.13312 0.00503159 9.05064 0.00503159 10.0002C0.00503159 12.1243 0.669382 14.0923 1.79863 15.7118C2.5302 12.8153 5.03949 9.99173 7.18715 8.01271Z"
        fill={color}
      />
      <path
        d="M11.9397 4.33598C13.1294 3.57753 14.155 3.0531 14.8205 2.85447C15.418 2.676 16.0043 2.54602 16.5594 2.45123C15.2766 1.33654 13.7086 0.542091 11.9776 0.195801C12.016 0.89419 12.0328 1.66095 11.6126 2.21802C11.413 2.48257 11.0753 2.84539 10.6359 3.23901C11.0662 3.57397 11.5035 3.93924 11.9397 4.33598Z"
        fill={color}
      />
      <path
        d="M12.3333 10.4987C12.1772 11.0276 11.9802 11.8744 11.7524 12.8548C11.0796 15.7496 10.4358 18.3399 9.684 19.9918C9.79074 19.9952 9.89745 20 10.005 20C12.3356 20 14.478 19.2006 16.1782 17.864C16.1111 15.0222 15.4298 11.0025 13.7137 8.01855C13.0481 8.51324 12.6899 9.29145 12.3333 10.4987Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default BasketballIcon;
