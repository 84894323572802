import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

import { IIconProperty } from 'types/iconProps';

const PoliticsIcon = ({ width, height, color }: IIconProperty) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 20 20"
      style={{ transform: 'scale(1)', width, height }}
    >
      <path
        d="M18.3337 18.9583H1.66699C1.32533 18.9583 1.04199 18.6749 1.04199 18.3333C1.04199 17.9916 1.32533 17.7083 1.66699 17.7083H18.3337C18.6753 17.7083 18.9587 17.9916 18.9587 18.3333C18.9587 18.6749 18.6753 18.9583 18.3337 18.9583Z"
        fill={color}
      />
      <path
        d="M12.0833 5.19153C11.3 5.19153 10.5167 5.04155 9.76667 4.74155C9.53333 4.64988 9.375 4.41654 9.375 4.1582V1.6582C9.375 1.44987 9.475 1.25821 9.65 1.14154C9.825 1.02487 10.0417 0.99986 10.2333 1.07486C11.425 1.54986 12.7417 1.54986 13.9333 1.07486C14.125 0.99986 14.3417 1.02487 14.5167 1.14154C14.6917 1.25821 14.7917 1.44987 14.7917 1.6582V4.1582C14.7917 4.41654 14.6333 4.64155 14.4 4.74155C13.65 5.04155 12.8667 5.19153 12.0833 5.19153ZM10.625 3.72485C11.575 4.01652 12.5917 4.01652 13.5417 3.72485V2.51656C12.5833 2.74989 11.5833 2.74989 10.625 2.51656V3.72485Z"
        fill={color}
      />
      <path
        d="M10 7.29175C9.65833 7.29175 9.375 7.00841 9.375 6.66675V4.16675C9.375 3.82508 9.65833 3.54175 10 3.54175C10.3417 3.54175 10.625 3.82508 10.625 4.16675V6.66675C10.625 7.00841 10.3417 7.29175 10 7.29175Z"
        fill={color}
      />
      <path
        d="M16.6663 18.9584H3.33301C2.99134 18.9584 2.70801 18.6751 2.70801 18.3334V9.16675C2.70801 7.15008 3.81634 6.04175 5.83301 6.04175H14.1663C16.183 6.04175 17.2913 7.15008 17.2913 9.16675V18.3334C17.2913 18.6751 17.008 18.9584 16.6663 18.9584ZM3.95801 17.7084H16.0413V9.16675C16.0413 7.85008 15.483 7.29175 14.1663 7.29175H5.83301C4.51634 7.29175 3.95801 7.85008 3.95801 9.16675V17.7084Z"
        fill={color}
      />
      <path
        d="M16.1831 10.625H3.81641C3.47474 10.625 3.19141 10.3417 3.19141 10C3.19141 9.65833 3.47474 9.375 3.81641 9.375H16.1831C16.5247 9.375 16.8081 9.65833 16.8081 10C16.8081 10.3417 16.5247 10.625 16.1831 10.625Z"
        fill={color}
      />
      <path d="M7.2832 10H6.0332V18.3333H7.2832V10Z" fill={color} />
      <path d="M10.6172 10H9.36719V18.3333H10.6172V10Z" fill={color} />
      <path d="M13.9502 10H12.7002V18.3333H13.9502V10Z" fill={color} />
    </SvgIcon>
  );
};
export default PoliticsIcon;
