import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

import { IIconProperty } from 'types/iconProps';

const TwitterIcon = ({ width, height, color }: IIconProperty) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 36 36"
      style={{ transform: 'scale(1)', width, height }}
    >
      <circle cx="18" cy="18" r="17.5" stroke="#ABABAB" />
      <path
        d="M17.7881 15.1922L17.8199 15.7112L17.29 15.6477C15.3613 15.404 13.6763 14.5778 12.2456 13.1902L11.5462 12.5017L11.3661 13.0101C10.9846 14.1435 11.2283 15.3405 12.0231 16.1455C12.447 16.5904 12.3516 16.654 11.6204 16.3891C11.3661 16.3044 11.1435 16.2408 11.1223 16.2726C11.0481 16.3468 11.3025 17.3107 11.5038 17.692C11.7794 18.2217 12.341 18.7407 12.9557 19.0479L13.475 19.2915L12.8603 19.3021C12.2668 19.3021 12.2456 19.3127 12.3092 19.5351C12.5212 20.2236 13.3584 20.9545 14.291 21.2723L14.948 21.4947L14.3757 21.8337C13.5279 22.321 12.5318 22.5964 11.5356 22.6175C11.0587 22.6281 10.6666 22.6705 10.6666 22.7023C10.6666 22.8082 11.9595 23.4014 12.7119 23.6344C14.9692 24.3229 17.6504 24.0264 19.6639 22.8506C21.0945 22.0138 22.5252 20.3507 23.1928 18.7407C23.5532 17.8827 23.9135 16.315 23.9135 15.5629C23.9135 15.0757 23.9453 15.0121 24.5387 14.4295C24.8884 14.0906 25.217 13.7198 25.2805 13.6139C25.3865 13.4126 25.3759 13.4126 24.8355 13.5927C23.9347 13.9105 23.8075 13.8681 24.2526 13.3915C24.5811 13.0525 24.9732 12.4381 24.9732 12.2581C24.9732 12.2263 24.8143 12.2792 24.6341 12.3746C24.4433 12.4805 24.0194 12.6394 23.7015 12.7347L23.1293 12.9148L22.61 12.5652C22.3238 12.3746 21.9211 12.1627 21.7092 12.0992C21.1687 11.9509 20.3421 11.9721 19.8546 12.1415C18.53 12.6182 17.6928 13.8469 17.7881 15.1922Z"
        fill="#ABABAB"
      />
    </SvgIcon>
  );
};

export default TwitterIcon;
