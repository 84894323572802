import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

import { IIconProperty } from 'types/iconProps';

const ImageIcon = ({ width, height, color }: IIconProperty) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 20 20"
      style={{ transform: 'scale(1)', width, height }}
    >
      <path
        d="M1.01551 16.8238L1.01553 16.8238L1.01381 16.8201C0.680031 16.0984 0.5 15.2227 0.5 14.19V5.81C0.5 4.08944 1.01093 2.77618 1.89355 1.89355C2.77618 1.01093 4.08944 0.5 5.81 0.5H14.19C15.9106 0.5 17.2238 1.01093 18.1064 1.89355C18.9891 2.77618 19.5 4.08944 19.5 5.81V11.9V14.19C19.5 15.9106 18.9891 17.2238 18.1064 18.1064C17.2238 18.9891 15.9106 19.5 14.19 19.5H5.81C3.41093 19.5 1.77734 18.5069 1.01551 16.8238ZM18.0442 10.8793L18.047 10.8817L18.177 10.9917L19 11.6881V10.61V5.81C19 4.31593 18.6043 3.09221 17.7561 2.24395C16.9078 1.39568 15.6841 1 14.19 1H5.81C4.31593 1 3.09221 1.39568 2.24395 2.24395C1.39568 3.09221 1 4.31593 1 5.81V14.19C1 15.0085 1.14029 15.7209 1.38462 16.3428L1.61209 16.9218L2.12863 16.5752L5.86863 14.0652L5.86974 14.0644C6.48811 13.647 7.36764 13.7008 7.90112 14.1666L7.90657 14.1714L7.91215 14.176L8.24836 14.4528C9.21597 15.2802 10.7498 15.279 11.7158 14.4493L15.8756 10.8794L15.8758 10.8793C16.4684 10.3702 17.4516 10.3702 18.0442 10.8793Z"
        fill="#BDBDBD"
        stroke="#BDBDBD"
      />
    </SvgIcon>
  );
};

export default ImageIcon;
