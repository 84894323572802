const colors = {
  pureWhite: '#FFFFFF',
  black1: '#000000',
  yellow1: '#3FADD5',
  black2: '#111111',
  black3: '#5A5A5E',
  grey1: '#bdbdbd',
};

export default colors;
