import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IIconProperty } from 'types/iconProps';

const ShiedTickIcon = ({ width, height, color }: IIconProperty) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 20 20"
      style={{ transform: 'scale(1)', width, height }}
    >
      <path
        d="M15.45 3.43337L10.8666 1.7167C10.3916 1.5417 9.61665 1.5417 9.14165 1.7167L4.55831 3.43337C3.67498 3.7667 2.95831 4.80003 2.95831 5.7417V12.4917C2.95831 13.1667 3.39998 14.0584 3.94165 14.4584L8.52498 17.8834C9.33331 18.4917 10.6583 18.4917 11.4666 17.8834L16.05 14.4584C16.5916 14.05 17.0333 13.1667 17.0333 12.4917V5.7417C17.0416 4.80003 16.325 3.7667 15.45 3.43337ZM12.9 8.10003L9.31665 11.6834C9.19165 11.8084 9.03331 11.8667 8.87498 11.8667C8.71665 11.8667 8.55831 11.8084 8.43331 11.6834L7.09998 10.3334C6.85831 10.0917 6.85831 9.6917 7.09998 9.45003C7.34165 9.20837 7.74165 9.20837 7.98331 9.45003L8.88331 10.35L12.025 7.20837C12.2666 6.9667 12.6666 6.9667 12.9083 7.20837C13.15 7.45003 13.15 7.85837 12.9 8.10003Z"
        fill="#29B6F6"
      />
    </SvgIcon>
  );
};

export default ShiedTickIcon;
