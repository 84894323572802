import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

import { IIconProperty } from 'types/iconProps';

const MessageIcon = ({ width, height, color }: IIconProperty) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 16 16"
      style={{ transform: 'scale(1)', width, height }}
    >
      <path
        d="M6.59993 12.5923L6.59996 12.5923L6.59629 12.5875C6.48179 12.4393 6.32669 12.3298 6.18259 12.2577C6.03937 12.1861 5.85701 12.1266 5.66732 12.1266H4.66732C3.1015 12.1266 1.83398 10.8618 1.83398 9.30659V8.63992V4.65325C1.83398 3.09802 3.1015 1.83325 4.66732 1.83325H11.334C12.8998 1.83325 14.1673 3.09802 14.1673 4.65325V9.30659C14.1673 10.8618 12.8998 12.1266 11.334 12.1266H10.334C9.9687 12.1266 9.62235 12.3015 9.40257 12.5907L9.40137 12.5923L8.40137 13.919L8.40065 13.9199C8.26121 14.1058 8.11151 14.1599 8.00065 14.1599C7.88979 14.1599 7.74009 14.1058 7.60065 13.9199L7.59993 13.919L6.59993 12.5923ZM4.66732 9.66659H8.66732C9.21679 9.66659 9.66732 9.21606 9.66732 8.66659C9.66732 8.11711 9.21679 7.66659 8.66732 7.66659H4.66732C4.11784 7.66659 3.66732 8.11711 3.66732 8.66659C3.66732 9.21606 4.11784 9.66659 4.66732 9.66659ZM4.66732 6.33325H11.334C11.8835 6.33325 12.334 5.88273 12.334 5.33325C12.334 4.78378 11.8835 4.33325 11.334 4.33325H4.66732C4.11784 4.33325 3.66732 4.78378 3.66732 5.33325C3.66732 5.88273 4.11784 6.33325 4.66732 6.33325Z"
        fill="#BDBDBD"
        stroke="#BDBDBD"
      />
    </SvgIcon>
  );
};

export default MessageIcon;
