import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IIconProperty } from 'types/iconProps';

const DollarIcon = ({ width, height, color }: IIconProperty) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 14 15"
      style={{ transform: 'scale(1)', width, height }}
    >
      <path
        d="M7.4375 9.7875H7.81667C8.19583 9.7875 8.51083 9.44917 8.51083 9.04083C8.51083 8.53333 8.33 8.43417 8.0325 8.32917L7.44333 8.125V9.7875H7.4375Z"
        fill="currentColor"
      />
      <path
        d="M6.9825 1.60938C3.7625 1.62105 1.155 4.24022 1.16666 7.46022C1.17833 10.6802 3.7975 13.2877 7.0175 13.2761C10.2375 13.2644 12.845 10.6452 12.8333 7.42522C12.8217 4.20522 10.2025 1.60355 6.9825 1.60938ZM8.31833 7.50105C8.77333 7.65855 9.38583 7.99688 9.38583 9.04105C9.38583 9.93938 8.68 10.6627 7.81667 10.6627H7.4375V11.0011C7.4375 11.2402 7.23917 11.4386 7 11.4386C6.76083 11.4386 6.5625 11.2402 6.5625 11.0011V10.6627H6.3525C5.39583 10.6627 4.62 9.85772 4.62 8.86605C4.62 8.62688 4.81833 8.42855 5.0575 8.42855C5.29667 8.42855 5.495 8.62688 5.495 8.86605C5.495 9.37355 5.88 9.78772 6.3525 9.78772H6.5625V7.81605L5.68166 7.50105C5.22667 7.34355 4.61417 7.00522 4.61417 5.96105C4.61417 5.06272 5.32 4.33938 6.18333 4.33938H6.5625V4.00105C6.5625 3.76188 6.76083 3.56355 7 3.56355C7.23917 3.56355 7.4375 3.76188 7.4375 4.00105V4.33938H7.6475C8.60417 4.33938 9.38 5.14438 9.38 6.13605C9.38 6.37522 9.18167 6.57355 8.9425 6.57355C8.70333 6.57355 8.505 6.37522 8.505 6.13605C8.505 5.62855 8.12 5.21438 7.6475 5.21438H7.4375V7.18605L8.31833 7.50105Z"
        fill="currentColor"
      />
      <path
        d="M5.495 5.96734C5.495 6.47484 5.67583 6.57401 5.97333 6.67901L6.56249 6.88318V5.21484H6.18333C5.80416 5.21484 5.495 5.55318 5.495 5.96734Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default DollarIcon;
