import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IIconProperty } from 'types/iconProps';

const UnPinIcon = ({ width, height, color }: IIconProperty) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 14 14"
      style={{ transform: 'scale(1)', width, height }}
    >
      <path
        d="M7.51212 1.30281C8.01546 0.799463 8.85132 0.76843 9.39774 1.30281L12.6976 4.60264C13.2183 5.12334 13.2183 5.96756 12.6976 6.48826C12.1942 6.9916 11.3584 7.02856 10.812 6.48826L10.5763 6.25243L8.60861 8.2201C9.20221 9.82665 8.80478 11.8092 7.51212 13.0879C7.37258 13.2259 7.15781 13.208 7.04072 13.0879L4.21216 10.2593L2.09097 12.3808C1.96079 12.511 1.74974 12.511 1.61957 12.3808C1.4994 12.2607 1.47829 12.0518 1.61957 11.9094L3.74083 9.78795L0.912458 6.95966C0.792297 6.8395 0.769174 6.63154 0.912458 6.48826C2.17368 5.22703 4.08936 4.77562 5.77994 5.39143L7.74761 3.42377L7.51212 3.18842C6.97484 2.64837 7.00878 1.80615 7.51212 1.30281ZM8.92634 1.77421C8.66599 1.51386 8.24388 1.51386 7.98353 1.77421C7.73688 2.02086 7.71346 2.43895 7.98353 2.71702L8.45493 3.18842C8.57509 3.30858 8.59205 3.52234 8.45493 3.65982L6.09888 6.01602C6.00375 6.11116 5.86076 6.13978 5.73634 6.08859C4.4251 5.54908 2.84056 5.70464 1.62928 6.73377L7.26628 12.3708C8.24605 11.2372 8.45493 9.53099 7.91189 8.26427C7.8489 8.11735 7.91189 7.9738 7.98353 7.90177L10.3405 5.54546C10.4607 5.42529 10.6673 5.40518 10.812 5.54546L11.2834 6.01685C11.5437 6.2772 11.9658 6.2772 12.2262 6.01685C12.4728 5.7702 12.4992 5.35389 12.2262 5.07404L8.92634 1.77421Z"
        fill="#BDBDBD"
      />
    </SvgIcon>
  );
};

export default UnPinIcon;
