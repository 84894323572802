import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IIconProperty } from 'types/iconProps';

const CopyIcon = ({ width, height, color }: IIconProperty) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 20 20"
      style={{ transform: 'scale(1)', width, height }}
    >
      <path
        d="M9.49984 18.9583H6.33317C2.67484 18.9583 1.0415 17.3249 1.0415 13.6666V10.4999C1.0415 6.84159 2.67484 5.20825 6.33317 5.20825H8.83317C9.17484 5.20825 9.45817 5.49159 9.45817 5.83325C9.45817 6.17492 9.17484 6.45825 8.83317 6.45825H6.33317C3.34984 6.45825 2.2915 7.51659 2.2915 10.4999V13.6666C2.2915 16.6499 3.34984 17.7083 6.33317 17.7083H9.49984C12.4832 17.7083 13.5415 16.6499 13.5415 13.6666V11.1666C13.5415 10.8249 13.8248 10.5416 14.1665 10.5416C14.5082 10.5416 14.7915 10.8249 14.7915 11.1666V13.6666C14.7915 17.3249 13.1582 18.9583 9.49984 18.9583Z"
        fill={color}
      />
      <path
        d="M14.1668 11.7916H11.5002C9.1585 11.7916 8.2085 10.8416 8.2085 8.49991V5.83324C8.2085 5.58324 8.3585 5.34991 8.59183 5.25824C8.82516 5.15824 9.09183 5.21658 9.27516 5.39158L14.6085 10.7249C14.7835 10.8999 14.8418 11.1749 14.7418 11.4082C14.6502 11.6416 14.4168 11.7916 14.1668 11.7916ZM9.4585 7.34158V8.49991C9.4585 10.1582 9.84183 10.5416 11.5002 10.5416H12.6585L9.4585 7.34158Z"
        fill={color}
      />
      <path
        d="M12.9998 2.29175H9.6665C9.32484 2.29175 9.0415 2.00841 9.0415 1.66675C9.0415 1.32508 9.32484 1.04175 9.6665 1.04175H12.9998C13.3415 1.04175 13.6248 1.32508 13.6248 1.66675C13.6248 2.00841 13.3415 2.29175 12.9998 2.29175Z"
        fill={color}
      />
      <path
        d="M5.8335 4.79175C5.49183 4.79175 5.2085 4.50841 5.2085 4.16675C5.2085 2.44175 6.6085 1.04175 8.3335 1.04175H10.5168C10.8585 1.04175 11.1418 1.32508 11.1418 1.66675C11.1418 2.00841 10.8585 2.29175 10.5168 2.29175H8.3335C7.30016 2.29175 6.4585 3.13341 6.4585 4.16675C6.4585 4.50841 6.17516 4.79175 5.8335 4.79175Z"
        fill={color}
      />
      <path
        d="M15.9917 14.7917C15.65 14.7917 15.3667 14.5084 15.3667 14.1667C15.3667 13.8251 15.65 13.5417 15.9917 13.5417C16.9417 13.5417 17.7084 12.7667 17.7084 11.8251V6.66675C17.7084 6.32508 17.9917 6.04175 18.3334 6.04175C18.675 6.04175 18.9584 6.32508 18.9584 6.66675V11.8251C18.9584 13.4584 17.625 14.7917 15.9917 14.7917Z"
        fill={color}
      />
      <path
        d="M18.3335 7.29174H15.8335C13.6168 7.29174 12.7085 6.38341 12.7085 4.16674V1.66674C12.7085 1.41674 12.8585 1.18341 13.0918 1.09174C13.3252 0.991739 13.5918 1.05007 13.7752 1.22507L18.7752 6.22507C18.9502 6.40007 19.0085 6.67507 18.9085 6.9084C18.8168 7.14174 18.5835 7.29174 18.3335 7.29174ZM13.9585 3.17507V4.16674C13.9585 5.69174 14.3085 6.04174 15.8335 6.04174H16.8252L13.9585 3.17507Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default CopyIcon;
