import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IIconProperty } from 'types/iconProps';

const FilterIcon = ({ width, height, color }: IIconProperty) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 22 20"
      style={{ transform: 'scale(1)', width, height }}
    >
      <path
        d="M19.0215 0H2.97905C2.57179 0.000320709 2.17337 0.118845 1.83216 0.341188C1.49095 0.563532 1.22162 0.880135 1.05684 1.25257C0.892061 1.62501 0.838928 2.03727 0.903889 2.43931C0.968851 2.84136 1.14911 3.21591 1.4228 3.5175L8.3753 11.1638V18.375C8.37536 18.516 8.41514 18.6541 8.49009 18.7734C8.56504 18.8928 8.67212 18.9887 8.79905 19.05C8.90049 19.1 9.0122 19.1257 9.1253 19.125C9.29576 19.1249 9.46109 19.0667 9.59405 18.96L11.0003 17.835L13.344 15.96C13.4317 15.8898 13.5026 15.8008 13.5512 15.6995C13.5999 15.5982 13.6252 15.4874 13.6253 15.375V11.1638L20.5778 3.5175C20.8515 3.21591 21.0317 2.84136 21.0967 2.43931C21.1617 2.03727 21.1085 1.62501 20.9438 1.25257C20.779 0.880135 20.5096 0.563532 20.1684 0.341188C19.8272 0.118845 19.4288 0.000320709 19.0215 0V0Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default FilterIcon;
