import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IIconProperty } from 'types/iconProps';

const DiagramIcon = ({ width, height, color }: IIconProperty) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 20 20"
      style={{ transform: 'scale(1)', width, height }}
    >
      <path
        d="M18.3333 18.9582H4.16667C2.44167 18.9582 1.04167 17.5582 1.04167 15.8332V1.6665C1.04167 1.32484 1.325 1.0415 1.66667 1.0415C2.00833 1.0415 2.29167 1.32484 2.29167 1.6665V15.8332C2.29167 16.8665 3.13333 17.7082 4.16667 17.7082H18.3333C18.675 17.7082 18.9583 17.9915 18.9583 18.3332C18.9583 18.6748 18.675 18.9582 18.3333 18.9582Z"
        fill={color}
      />
      <path
        d="M4.16652 14.7917C4.02485 14.7917 3.87485 14.7417 3.75819 14.6417C3.49985 14.4167 3.46652 14.025 3.69152 13.7584L7.51652 9.29168C7.93319 8.80834 8.53319 8.51668 9.16652 8.49168C9.79985 8.47502 10.4249 8.70834 10.8749 9.15834L11.6665 9.95002C11.8749 10.1583 12.1499 10.2583 12.4415 10.2583C12.7332 10.25 12.9999 10.1167 13.1915 9.89168L17.0165 5.42502C17.2415 5.16669 17.6332 5.13334 17.8999 5.35834C18.1582 5.58334 18.1915 5.97501 17.9665 6.24167L14.1415 10.7084C13.7249 11.1917 13.1249 11.4833 12.4915 11.5083C11.8582 11.525 11.2332 11.2917 10.7832 10.8417L9.99985 10.05C9.79152 9.84168 9.51652 9.73335 9.22485 9.74168C8.93319 9.75002 8.66652 9.88335 8.47485 10.1084L4.64985 14.575C4.51652 14.7167 4.34152 14.7917 4.16652 14.7917Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default DiagramIcon;
