import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IIconProperty } from 'types/iconProps';

const DangerIcon = ({ width, height, color }: IIconProperty) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 16 17"
      style={{ transform: 'scale(1)', width, height }}
    >
      <path
        d="M14.5067 11.113L10.24 3.43301C9.66665 2.39967 8.87332 1.83301 7.99998 1.83301C7.12665 1.83301 6.33332 2.39967 5.75998 3.43301L1.49332 11.113C0.953318 12.093 0.893318 13.033 1.32665 13.773C1.75999 14.513 2.61332 14.9197 3.73332 14.9197H12.2667C13.3867 14.9197 14.24 14.513 14.6733 13.773C15.1067 13.033 15.0467 12.0863 14.5067 11.113ZM7.49998 6.49967C7.49998 6.22634 7.72665 5.99967 7.99998 5.99967C8.27332 5.99967 8.49998 6.22634 8.49998 6.49967V9.83301C8.49998 10.1063 8.27332 10.333 7.99998 10.333C7.72665 10.333 7.49998 10.1063 7.49998 9.83301V6.49967ZM8.47332 12.3063C8.43998 12.333 8.40665 12.3597 8.37332 12.3863C8.33332 12.413 8.29332 12.433 8.25332 12.4463C8.21332 12.4663 8.17332 12.4797 8.12665 12.4863C8.08665 12.493 8.03998 12.4997 7.99998 12.4997C7.95998 12.4997 7.91332 12.493 7.86665 12.4863C7.82665 12.4797 7.78665 12.4663 7.74665 12.4463C7.70665 12.433 7.66665 12.413 7.62665 12.3863C7.59332 12.3597 7.55998 12.333 7.52665 12.3063C7.40665 12.1797 7.33332 12.0063 7.33332 11.833C7.33332 11.6597 7.40665 11.4863 7.52665 11.3597C7.55998 11.333 7.59332 11.3063 7.62665 11.2797C7.66665 11.253 7.70665 11.233 7.74665 11.2197C7.78665 11.1997 7.82665 11.1863 7.86665 11.1797C7.95332 11.1597 8.04665 11.1597 8.12665 11.1797C8.17332 11.1863 8.21332 11.1997 8.25332 11.2197C8.29332 11.233 8.33332 11.253 8.37332 11.2797C8.40665 11.3063 8.43998 11.333 8.47332 11.3597C8.59332 11.4863 8.66665 11.6597 8.66665 11.833C8.66665 12.0063 8.59332 12.1797 8.47332 12.3063Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default DangerIcon;
