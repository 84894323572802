import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IIconProperty } from 'types/iconProps';

const Element4Icon = ({ width, height, color }: IIconProperty) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 20 20"
      style={{ transform: 'scale(1)', width, height }}
    >
      <path
        d="M17.9167 9.08317V3.4165C17.9167 2.1665 17.3833 1.6665 16.0583 1.6665H12.6917C11.3667 1.6665 10.8333 2.1665 10.8333 3.4165V9.08317C10.8333 10.3332 11.3667 10.8332 12.6917 10.8332H16.0583C17.3833 10.8332 17.9167 10.3332 17.9167 9.08317Z"
        fill={color}
      />
      <path
        d="M9.16667 10.9165V16.5832C9.16667 17.8332 8.63333 18.3332 7.30833 18.3332H3.94167C2.61667 18.3332 2.08333 17.8332 2.08333 16.5832V10.9165C2.08333 9.6665 2.61667 9.1665 3.94167 9.1665H7.30833C8.63333 9.1665 9.16667 9.6665 9.16667 10.9165Z"
        fill={color}
      />
      <path
        d="M17.9167 16.5833V14.25C17.9167 13 17.3833 12.5 16.0583 12.5H12.6917C11.3667 12.5 10.8333 13 10.8333 14.25V16.5833C10.8333 17.8333 11.3667 18.3333 12.6917 18.3333H16.0583C17.3833 18.3333 17.9167 17.8333 17.9167 16.5833Z"
        fill={color}
      />
      <path
        d="M9.16667 5.74984V3.4165C9.16667 2.1665 8.63333 1.6665 7.30833 1.6665H3.94167C2.61667 1.6665 2.08333 2.1665 2.08333 3.4165V5.74984C2.08333 6.99984 2.61667 7.49984 3.94167 7.49984H7.30833C8.63333 7.49984 9.16667 6.99984 9.16667 5.74984Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default Element4Icon;
