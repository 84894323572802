import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IIconProperty } from 'types/iconProps';
const CourthouseIcon = ({ width, height, color }: IIconProperty) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 20 20"
      style={{ transform: 'scale(1)', width, height }}
    >
      <path
        d="M18.3333 18.9585H1.66667C1.325 18.9585 1.04167 18.6752 1.04167 18.3335C1.04167 17.9918 1.325 17.7085 1.66667 17.7085H18.3333C18.675 17.7085 18.9583 17.9918 18.9583 18.3335C18.9583 18.6752 18.675 18.9585 18.3333 18.9585Z"
        fill={color}
      />
      <path
        d="M12.0833 5.19153C11.3 5.19153 10.5167 5.04155 9.76667 4.74155C9.53333 4.64988 9.375 4.41654 9.375 4.1582V1.6582C9.375 1.44987 9.475 1.25821 9.65 1.14154C9.825 1.02487 10.0417 0.99986 10.2333 1.07486C11.425 1.54986 12.7417 1.54986 13.9333 1.07486C14.125 0.99986 14.3417 1.02487 14.5167 1.14154C14.6917 1.25821 14.7917 1.44987 14.7917 1.6582V4.1582C14.7917 4.41654 14.6333 4.64155 14.4 4.74155C13.65 5.04155 12.8667 5.19153 12.0833 5.19153ZM10.625 3.72485C11.575 4.01652 12.5917 4.01652 13.5417 3.72485V2.51656C12.5833 2.74989 11.5833 2.74989 10.625 2.51656V3.72485Z"
        fill={color}
      />
      <path
        d="M10 7.2915C9.65833 7.2915 9.375 7.00817 9.375 6.6665V4.1665C9.375 3.82484 9.65833 3.5415 10 3.5415C10.3417 3.5415 10.625 3.82484 10.625 4.1665V6.6665C10.625 7.00817 10.3417 7.2915 10 7.2915Z"
        fill={color}
      />
      <path
        d="M16.6667 18.9582H3.33333C2.99167 18.9582 2.70833 18.6748 2.70833 18.3332V9.1665C2.70833 7.14984 3.81667 6.0415 5.83333 6.0415H14.1667C16.1833 6.0415 17.2917 7.14984 17.2917 9.1665V18.3332C17.2917 18.6748 17.0083 18.9582 16.6667 18.9582ZM3.95833 17.7082H16.0417V9.1665C16.0417 7.84984 15.4833 7.2915 14.1667 7.2915H5.83333C4.51667 7.2915 3.95833 7.84984 3.95833 9.1665V17.7082Z"
        fill={color}
      />
      <path
        d="M16.1834 10.625H3.81673C3.47506 10.625 3.19173 10.3417 3.19173 10C3.19173 9.65833 3.47506 9.375 3.81673 9.375H16.1834C16.5251 9.375 16.8084 9.65833 16.8084 10C16.8084 10.3417 16.5251 10.625 16.1834 10.625Z"
        fill={color}
      />
      <path d="M7.28353 10H6.03353V18.3333H7.28353V10Z" fill={color} />
      <path d="M10.6169 10H9.36686V18.3333H10.6169V10Z" fill={color} />
      <path d="M13.9502 10H12.7002V18.3333H13.9502V10Z" fill={color} />
    </SvgIcon>
  );
};

export default CourthouseIcon;
